<template>
  <div class="pagination-wrapper pagination-wrapper--right">
    <transition name="fade">
      <div class="pagination-right">
      <div class="pagination-display">
                <span>
                {{ totalC }} {{$t('pagination.results')}}, {{$t('pagination.page')}}:
                </span>
        <b-pagination v-model="currPage"
                      :total-rows="totalC"
                      :per-page="perP"
                      limit="5"
                      hide-goto-end-buttons
                      @change="loadItems"
                      aria-controls="my-table">
        </b-pagination>
        <span class="per-page">
                  {{$t('pagination.of')}} {{ Math.ceil((totalC / perP)) }},
                </span>
      </div>
      <div class="pagination-display pagination-display--per">
        <span>{{$t('pagination.display')}}:</span>
        <b-form-select v-model="perP"
                       id="filterSelectPerPage"
                       value-field="id"
                       text-field="name"
                       @change="loadItems"
                       :options="[1,10,15,25,50,100]"
                       style="max-width:75px">
        </b-form-select>
        <span class="per-page">{{$t('pagination.per_page')}}</span>
      </div>
    </div> <!-- #pagination-right-->
    </transition>
  </div> <!-- #pagination-wrapper-->
</template>

<script>

export default {
  components: {

  },
  props: {
    currentPage: {
      default: 1
    },
    totalCount: {
      default: 1
    },
    perPage: {
      default: 1
    }
  },
  data() {
    return {
      currPage: this.currentPage,
      totalC: this.totalCount,
      perP: this.perPage,
    }
  },
  computed: {
  },
  mounted() {
  },
  watch: {
    currentPage() {
      this.currPage = this.currentPage;
    },
    totalCount() {
      this.totalC = this.totalCount;
    },
    perPage() {
      this.perP = this.perPage;
    }
  },
  methods: {
    loadItems() {
      setTimeout(() => {  // this.currPage changes after @change event so have to wait for currPage change
        this.$emit('reset-checkbox');
        this.$emit('load-items', {currentPage: this.currPage, totalCount: this.totalC, perPage: this.perP});
      }, 50)
    }
  }
}
</script>

<template>
  <b-form-group :label="label">
    <multiselect :placeholder="placeholder"
                 :label="optionsLabel"
                 :track-by="optionsTrackBy"
                 v-model="filter"
                 :options="options"
                 :multiple="multiple"
                 :allow-empty="allowEmpty"
                 :deselect-label="deselectLabel"
                 @input="applyFilter">
    </multiselect>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Select",
  props: {
    options: {}, label: {}, placeholder: {}, filterName: {}, filterQuery: {}, multiple: {}, optionsLabel: {},
    optionsTrackBy: {}, storeData: {}, defaultFilter: {type: Object}, allowEmpty: {type: Boolean, default: true},
    operator: {type: String}, emitEventValue: {type: Boolean, default: false}
  },
  components: {Multiselect},
  data() {
    return {
      filter: null,
    };
  },
  mounted() {
    this.$root.$on('resetFilters', () => {
      this.filter = this.defaultFilter;
      if (this.defaultFilter) {
        this.applyFilter();
      }
    })
    if (this.defaultFilter) {
      this.filter = this.defaultFilter;
      this.applyFilter();
    }

    this.checkStoreData();
  },
  methods: {
    emitRemoveSelect(value) {
      if (this.emitEventValue) {
        this.$emit('changedFilterValue', value);
      }
    },
    checkStoreData() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.storeData && this.storeData.hasOwnProperty(this.filterName)) {
        if (this.storeData[this.filterName] !== null) {
          this.filter = this.options.find(o => o.value === this.storeData[this.filterName]);
          this.applyFilter();
        }
      }
    },
    applyFilter() {
      if (this.multiple === true && this.filter.length > 0) {
        if (this.filter.length > 0) {
          let filter = '';
          let values = '';
          this.filter.forEach((value, i) => {
            values += value[this.optionsTrackBy];
            if (i !== this.filter.length - 1) {
              values += ',';
            }
          });
          filter += `&filters[${this.filterName}][0][value]=${values}`;
          filter += `&filters[${this.filterName}][0][operator]=${this.operator ?? 'in'}`;
          this.filterQuery[this.filterName] = filter;
        } else {
          delete this.filterQuery[this.filterName];
          this.$emit('store', {filterName: this.filterName, value: null});
        }
      } else if (this.filter && this.filter.length > 0) {
        this.filterQuery[this.filterName] = '&filters[' + this.filterName + '][0][value]=' + this.filter.value;
        this.$emit('store', {filterName: this.filterName, value: this.filter.value});
      } else {
        if (this.filter === null || !this.filter || (Array.isArray(this.filter) && this.filter.length === 0)) {
          delete this.filterQuery[this.filterName];
          this.$emit('store', {filterName: this.filterName, value: null});
          this.emitRemoveSelect();
        } else {
          const filterValue = this.optionsTrackBy ? this.filter[this.optionsTrackBy] : this.filter.value;
          this.filterQuery[this.filterName] = '&filters[' + this.filterName + '][0][value]=' + filterValue;
          this.filterQuery[this.filterName] += `&filters[${this.filterName}][0][operator]=${this.operator ?? 'eq'}`;
          this.$emit('store', {filterName: this.filterName, value: this.filter.value});
        }
      }
      if(this.filter === null){
        this.$emit(this.filterName, null);
      }else{
        this.$emit(this.filterName, this.filter.value);
      }
    }
  },
  computed: {
    deselectLabel: function () {
      return this.allowEmpty ? 'Press enter to remove' : "Can't remove";
    }
  },
  watch: {}

}
</script>

